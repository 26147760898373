body {
    color: #0e4d3b;
}

.header {
    margin: 2%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.frame {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    margin: 0 2%;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.footer {
    margin: 2%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttonGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 70%;
}

.gameFrame {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.canvas {
    background-color: white;
    position: relative;
    display: flex;
    flex: 1;
    width: 90%;
    margin: 0 2%;
    border: black solid 2px;
}

.game1box {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #0e4d3b;
    border-width: 2px;
}
